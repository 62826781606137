import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../helpers";
import { useAuthContext } from "../../AuthContext";


const AppHeader = () => {
    
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();

    removeToken();
    navigate("/signin", { replace: true });
    window.location.reload();


  };

  return (
    <>
        {user ? (
          <>
            <Button className="auth_button_login" href="/profile" type="link">
              {user.username}
            </Button>

            <Button
              className="auth_button_signUp"
              type="primary"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button className="auth_button_login" href="/signin" type="link">
              Login
            </Button>
            <Button
              className="auth_button_signUp"
              href="/signup"
              type="primary"
            >
              SignUp
            </Button>
          </>
        )}
    </>
  );
};

export default AppHeader;