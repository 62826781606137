import React, { useEffect, useState } from 'react';
import InteractiveMap from './InteractiveMap';
import './App.css';
import { useParams } from 'react-router-dom';

function App() {

  const { lat, lng } = useParams();
  
  const [latitude, setLatitude] = useState(lat?lat:40.651601518462535);  
  const [longitude, setLongitude] = useState(lng?lng:22.982711791992188);  

  const requestCameraAccess = async () => {
    try {
      const constraints = {
        video: {
          facingMode: { ideal: "environment" }
        }
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const video = document.querySelector("video");
      if (video) {
        video.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing the camera: ", error);
    }
  };
  
  const requestLocationAccess = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude: ", position.coords.latitude);
          console.log("Longitude: ", position.coords.longitude);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => console.error("Error accessing location: ", error)
      );
    } else {
      console.error("Geolocation is not available.");
    }
  };

  useEffect(() => {
    // Request permissions
    // requestCameraAccess();
    requestLocationAccess();
  }, []);

  return (
    <div className="App">
      <video style={{ display: 'none' }} autoPlay></video> {/* Hidden video for camera access */}
      <InteractiveMap latitude={latitude} longitude={longitude}/>
  </div>
  );
}

export default App;