import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { replaceMarkdownImages } from './helpers';
import { API } from './constants';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';

const fetchMarker = (id) =>{

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API}/markers/${id}`,
      headers: { }
    };
  
    return axios.request(config)
    .then((response) => {
      let marker = response.data;
      return marker;
    })
    .catch((error) => {
      console.log(error);
    });
  
  }

const Page = () => {
  const { id } = useParams(); // Get the dynamic parameter from the URL
  const [marker, setMarker] = useState(null);

   useEffect(() => {
      if (id) {
       
        fetchMarker(id).then(marker => {
          marker.description = replaceMarkdownImages(marker.description);
          setMarker(marker);
        });        
      }
    }, [id]);

  return marker?<Layout>
      <Header
      style={{
        height: '300px',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url("${API}${marker?.photo?.formats?.small?.url}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',          
        flexDirection: 'column', // Stack items vertically

      }}>
        <Title
                  level={1}
                  style={{
                    color: 'white',
                    fontSize: '3rem',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    marginBottom: '8px',
                  }}
                  >
          {marker?.name}
        </Title>
        </Header>
        <Content
                style={{
                  padding: '24px',
                  backgroundColor: '#f0f2f5',
                }}
                >
            <div
            style={{
              maxWidth: '100%',
              margin: '0 auto',
              backgroundColor: 'white',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <section>
              {marker?.description}
            <p>
            {marker.source?<small><Link to={marker.source} target="_blank" rel="noopener noreferrer" >soure</Link></small>:null}
            </p>
            </section>
            <section>
            <iframe  allow="fullscreen" allowFullScreen src={`${marker?.url}`}></iframe>
            </section>

          </div>
      </Content>
      </Layout>:<></>;
};

export default Page;
