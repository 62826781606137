import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Alert, Button, Card, Col, Form, Input, Row, Spin, 
  message, } from "antd";
import useScreenSize from "./hooks/useScreenSize";
import TextArea from "antd/es/input/TextArea";

const ContactForm = () => {
  const [form] = Form.useForm();

  const { isDesktopView } = useScreenSize();
  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    message: "",
    name: "",
    captchaInput: "",
  });

  const [error, setError] = useState(null);
  const [captcha, setCaptcha] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateCaptcha = () => {
    const randomCaptcha = Math.random().toString(36).substring(2, 8).toUpperCase(); // 6-character CAPTCHA
    setCaptcha(randomCaptcha);
  };

    // Generate a new CAPTCHA on load and when needed
    useEffect(() => {
      generateCaptcha();
    }, []);


  const onFinish = async (values) => {
    try{

      setIsLoading(true);
      setError(null);
  
      if (values.captchaInput !== captcha) {
        alert("CAPTCHA incorrect. Try again.");
        generateCaptcha(); // Refresh CAPTCHA on failure
        return;
      }
  
      // Prepare data for Strapi API
      const postData = {
        subject: values.subject,
        email: values.email,
        name: values.name,
        message: values.message,
        form: 'I SEE YOU WATCHING',
      };
  
        const response = await axios.post("https://api.stap.n2048.com/contacts", postData, {
          headers: { "Content-Type": "application/json" },
        });
  
        if (response.status === 200 || response.status === 201) {

          message.success("Message sent successfully!");
          form.resetFields();
          generateCaptcha();
        }
        } catch (error) {
          console.error(error);
          setError(error?.message ?? "Something went wrong!");
        } finally {
          setIsLoading(false);
        }

  };

  return (
<Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Contact">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onChange={handleChange}
              autoComplete="off"
            >

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Name"/>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true }]}
              >
                <Input placeholder="Subject" />
              </Form.Item>


              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true }]}
              >
                <TextArea placeholder="Message" />
              </Form.Item>

             <div style={styles.captchaContainer}>
           <span style={styles.captchaText}>{captcha}</span>
           <button type="button" onClick={generateCaptcha} style={styles.refreshButton}>🔄</button>
         </div>
        
         <Form.Item
                label="Validation"
                name="captchaInput"
                rules={[
                  {
                    required: true,
                    type: "text",
                  },
                ]}
              >
                <Input placeholder="Enter CAPTCHA" />
              </Form.Item>


              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Send {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};


const styles = {
  captchaContainer: { display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", marginBottom: "10px" },
  captchaText: { fontSize: "18px", fontWeight: "bold", background: "#eee", padding: "5px 10px", borderRadius: "5px" },
  refreshButton: { background: "none", border: "none", cursor: "pointer", fontSize: "18px" },
};

export default ContactForm;
