import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { replaceMarkdownImages } from './helpers';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import { API } from './constants';


const fetchPage = (title) =>{

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.stap.n2048.com/pages?_where[title]=${title}`,
      headers: { }
    };
  
    return axios.request(config)
    .then((response) => {
      let page = response.data[0];
      return page;
    })
    .catch((error) => {
      console.log(error);
    });
  
  }
  
const Page = () => {
  const { title } = useParams(); // Get the dynamic parameter from the URL
  const [page, setPage] = useState(null);

   useEffect(() => {
      if (title) {       
        fetchPage(title).then(page => {
          page.content = replaceMarkdownImages(page.content);
          setPage(page);
        });        
      }
    }, [title]);


  return (
    <Layout>
    <Header
      style={{
        height: '300px',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url("${API}${page?.header?.formats?.small?.url}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',          
        flexDirection: 'column', // Stack items vertically

      }}>
        <Title
                  level={1}
                  style={{
                    color: 'white',
                    fontSize: '3rem',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    marginBottom: '8px',
                  }}
                  >
          {page?.title}
        </Title>
        </Header>
        <Content
                style={{
                  padding: '24px',
                  backgroundColor: '#f0f2f5',
                }}
                >
            <div
            style={{
              maxWidth: '100%',
              margin: '0 auto',
              backgroundColor: 'white',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {page?.content}
          </div>
      </Content>
    </Layout>

  );
};

export default Page;
