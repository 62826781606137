import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from "../../constants";
import useScreenSize from "../../hooks/useScreenSize";
import { getIcon, replaceMarkdownImages } from "../../helpers";
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { Layout, Typography } from "antd";
import dayjs from "dayjs";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const fetchEvent = async(id)=>{
  try{
    const response = await axios.get(`${API}/events/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching event:", error);
    return null;
  }
};


const Event = () => {
  const { isDesktopView } = useScreenSize();
  const {id} = useParams();
  const [event, setEvent] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  
  useEffect(() => {
    const getEvent = async () => {
      const response = await fetchEvent(id); // Use `await` to resolve the promise
      setEvent(response);


      const start = dayjs(`${response.startDate}T${response.startTime}`, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD MMMM YYYY, HH:mm A");
      const end = dayjs(`${response.endDate}T${response.endTime}`, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD MMMM YYYY, HH:mm A");
      setStart(start);
      setEnd(end);
      
    };

    getEvent(); // Call the async function
  },[id]);



  return event ?
    <Layout>
      <Header
        style={{
          height: '300px',
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url("${API}${event.image?.formats?.large?.url}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',          
          flexDirection: 'column', // Stack items vertically

        }}>
          <Title
                    level={1}
                    style={{
                      color: 'white',
                      fontSize: '3rem',
                      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                      marginBottom: '8px',
                    }}
                    >
            {event.title}
          </Title>
          <Text 
            style={{
              color: 'white',
              fontSize: '1.2rem',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
              marginBottom: '8px',
            }}
            >
            From {start}
            </Text>
            <Text 
            style={{
              color: 'white',
              fontSize: '1.2rem',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
              marginBottom: '8px',
            }}
            >
            Until {end}
          </Text>
      </Header>
      <Content
              style={{
                padding: '24px',
                backgroundColor: '#f0f2f5',
              }}
              >
          <div
          style={{
            maxWidth: '100%',
            margin: '0 auto',
            backgroundColor: 'white',
            padding: '24px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
      {replaceMarkdownImages(event.description)}

      <br/>
      <br/>
     
      <MapContainer 
          center={[event.marker.latitude, event.marker.longitude]} 
          zoom={18} 
          style={{ height: '400px', width: '80vw' }}
          scrollWheelZoom={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="STAP"
            />
              <Marker                 
                // icon={getIcon('location')}
                icon={getIcon(event.marker.type)}
                alt={event.marker.name}
                key={event.marker.id} 
                position={[event.marker.latitude, event.marker.longitude]} 
              >
                <Popup>
                {event.marker && event.marker.photo && event.marker.photo.formats ? 
                    <img className="popup-thumbnail" 
                      src={`${API}${event.marker.photo.formats.thumbnail.url}`} 
                      alt={event.marker?.name}></img>
                      :<>{event.marker.type}</>}
                      {event.marker?.url ? (<li><Link to={"/live/" + event.marker?.id}>Watch Live Stream</Link></li>) : null}
                </Popup>
              </Marker>
          </MapContainer>

        </div>
      </Content>

    </Layout>
    :<div></div>;
}

export default Event;